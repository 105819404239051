<template>
<div id="app">   
  <CRow>
    <CCol sm="8" md="8">
        <CCard>
          <CCardHeader>          
            <h4>KYC Sample Image</h4> 
            <span>Required dimension (width*height) :  000 x 000</span> 
          </CCardHeader>
          <CCardBody>
              <CForm>

                <CRow>
                  <CCol col="12" class="text-left">                   
                    <CAlert v-bind:color="color" v-if="seen" id="alert">{{ msg }}</CAlert>
                  </CCol> 
                </CRow> 
                <div class="text-center">
                    <b-spinner variant="success" label="Spinning" v-if="spinner" ></b-spinner>
                </div>

                <CInput
                    label="Image Name"
                    v-model="KycImgName"
                    :disabled="!isDisabledName"
                    placeholder ="Enter Promo Name"
                    horizontal
                /> 

                <b-row>
                  <b-col sm="3">                    
                     <label for="input-small" >Upload Image</label>
                  </b-col>   
                  <b-col sm="9"> 
                      <input type="file" :v-model="srcFile" ref="myFile" @change="onFileChangedImg">
                      <b-button-group>    
                      <CButton @click="onUplonUploadImg" :disabled="!isDisabledbtnImg" color="success">Upload</CButton>
                      <CButton  v-on:click="actionShowImg()" color="warning">Show</CButton>
                      <CButton  v-on:click="actionDownloadImg()" color="primary">Download</CButton>                      
                      </b-button-group>   
                  </b-col>                  
                </b-row><br/>
              
                <b-row>
                  <b-col sm="3">                    
                     <label for="input-small" >Preview Image </label>
                     <!-- {{image.width}} * {{image.height}} -->
                  </b-col>   
                  <b-col sm="9">
                      <b-img @error="onImageLoadFailure($event)" thumbnail fluid v-bind="imgProps" :src="'data:image/png;base64,'+ imgKyc " alt=""></b-img>
                  </b-col>                  
                </b-row><br/>

                <b-row>
                  <b-col sm="3">
                  </b-col>   
                  <b-col sm="9"> 
                      <CButton  v-on:click="actionRefresh()" color="primary">Refresh Preview</CButton>
                  </b-col>                  
                </b-row><br/>
                  
              </CForm>
          </CCardBody>
        </CCard>
      </CCol>
     </CRow>  
</div>
</template> 

<script>
 import AccountService from '@/api/AccountService.js';
 
  export default { 
    name: 'kyc_img',
    components: { 
    },     
    data() {
      return { 
          id : this.$route.params.id,
          image:{
            size:0,
            height:0,
            width:0
          },
          srcFile:'',
          imgType : '',
          imgKyc:'',  
          KycImgName:'',       
          defaultImageKey:'',
          selectedFile: null, 
          isDisabledbtnImg:false,          
          imgProps: {  width: 200, height: 200  }, 
          isDisabledName: false, 
          spinner:false,
          seen:false,
          msg :'',
          color:'',
          count:1
        };      
    },
    created () { 
        this.getKycImageById(this.id);           
    },
    methods: {
     
      onImageLoadFailure (event) {
            event.target.src = 'img/default.png'     
      },

      onFileChangedImg(){
         this.selectedFile = event.target.files[0];
         this.isDisabledbtnImg = true;
      },

      actionRefresh(){
          this.getKycImageById(this.id);
          this.actionShowImg();
      },

      actionShowImg(){   
         this.getKycImageById(this.id);    
         if(this.defaultImageKey === ''){
             this.msg   =  "KYC Image Not Defined !"; 
             this.color = 'info'; this.seen  = true;  
             setTimeout( () =>  this.seen=false , 5000);  this.spinner = false;  
         } else {
             var key  = this.defaultImageKey;  
             var temp = key.split("."); var type  = temp[1];        
             this.downloadKycImage(key,type,"true");
         }        
      },

      actionDownloadImg(){
        this.getKycImageById(this.id);    
         if(this.defaultImageKey === ''){
             this.msg   =  "KYC Image Not Defined !"; 
             this.color = 'info'; this.seen  = true;  
             setTimeout( () =>  this.seen=false , 5000);  this.spinner = false;  
         } else {
             var key  = this.defaultImageKey;  
             var temp = key.split("."); var type  = temp[1];        
             this.downloadKycImage(key,type);  
         }        
      },      

      async onUplonUploadImg() { 

          this.spinner = true;
          this.imageLoaded = false;
          let file = this.$refs.myFile.files[0];

          if(!file || file.type.indexOf('image/') !== 0) return;
      
          this.image.size = file.size;          
          let reader = new FileReader();

           
            reader.readAsDataURL(file);
            reader.onload = evt => {
              let img = new Image();
              img.onload = () => {
                this.image.width  = img.width;
                this.image.height = img.height;
                this.imageLoaded  = true; 
              }
              img.src = evt.target.result;
            }
            reader.onerror = evt => { console.error(evt); } 
            setTimeout( () =>  this.actionSubmit() , 5000);          

      },     

      async actionSubmit(){
          //if(this.image.width === 200 && this.image.height === 100) {  
          if(this.image.width !== 0 && this.image.height !== 0) {
            const formData = new FormData()
            formData.append('resource', this.selectedFile, this.selectedFile.name);  //console.log(formData);         
            try {                        
                const resp = await AccountService.uploadKycImage(this.id,formData);   //console.log(resp);
                if(resp.message==="Success"){   
                   this.msg   = "Upload KYC Image Successfully!"; 
                   this.color = 'success'; this.seen  = true;            
                } else {
                   this.msg   =  !resp.data.message ? "Upload KYC Image Failed!" : resp.data.message; 
                   this.color = 'danger'; this.seen  = true;              
                } 
                setTimeout( () =>  this.seen=false , 5000);  this.spinner = false;
            } catch (error) { console.log(error); }
          } else { 
              this.msg  = "Failed, detected dimension " + this.image.width +" * "+ this.image.height; 
              this.color = 'warning'; this.seen  = true;  this.spinner = false;
              setTimeout( () =>  this.seen=false , 5000);
          };
      }, 
    
      downloadKycImage: function(key,type,show) { 
          AccountService.downloadKycImage(key,type).then(resp => { 

              if(show==="true"){ 
                  this.imgKyc = resp;  this.imgType = type;
              } else {
                  var ImageBase64 = resp;
                  var a = document.createElement("a"); 
                  a.href = "data:image/png; base64," + ImageBase64; 
                  a.download = key; a.click();
              }                  
           
          }, error => {
            console.log('error');    
          });
      }, 
      
      getKycImageById: function(id) { 
          AccountService.getKycImageById(id).then(resp => {  //console.log(resp);          
             this.KycImgName = resp.name;            
             this.defaultImageKey = resp.imagePath;        
          }, error => {
            console.log('error');    
          });
      }, 
    }
  }
</script>